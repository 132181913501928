import React from "react"
import Helmet from "react-helmet";

// type MetaProps = JSX.IntrinsicElements["meta"];

interface IHead {
    title: string;
    description?: string;
    lang?: string;
    meta?: (JSX.IntrinsicElements["meta"])[];
    icon?: string;
  }

const Head: React.FC<IHead> = ({title, description, lang, meta, icon}) => {
    return (
        <React.Fragment>
            <Helmet htmlAttributes={{lang}} meta={[{
                name: "description",
                content: description
            }]}>
                <title>{title}</title>
                <link rel="icon" 
                href={`data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>${icon}</text></svg>`} />
            </Helmet>
        </React.Fragment>
    )
}

Head.defaultProps = {
    lang: 'en-us',
    description: '',
    icon: '🍕',
    meta: [] as (JSX.IntrinsicElements["meta"])[]
}

export default Head
