import React, { ReactNode } from "react"
import Header from "./header"
import Head from "./head"
import { graphql, useStaticQuery } from "gatsby"

interface LayoutProps {
    children: ReactNode
    title: string;
    icon: string;
    description?: string;
    showHeader?: boolean;
}

const Layout: React.FC<LayoutProps> = ({children, title, icon, showHeader}) => {
  const desc = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
        }
      } 
    }
  `)


  return (
      <React.Fragment>
        <Head title={title} icon={icon} description={desc.site.siteMetadata.description} />
        <Header isVisible={showHeader ?? true} />
        <main>
            {children}
        </main>
      </React.Fragment>
  )
}
export default Layout
